
<template>
  <v-app-bar
    absolute
    color="grey lighten-5"
    elevate-on-scroll
    scroll-target="#scrolling-techniques-7"
    app
  >
    <v-toolbar-title>
      <v-btn text @click="$store.dispatch('toggleSidebarCollapse')">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      {{ $route.name }}
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn icon>
      <v-avatar color="brown">
        <span class="white--text headline">{{ user.user_initial }}</span>
      </v-avatar>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  computed: {
    user () {
      return this.$store.getters.user
    }
  }
}
</script>
