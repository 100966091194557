<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    fixed
    permanent
    app>
    <v-list flat>
      <!-- dashboard -->
      <v-list-item
        to="/dashboard">
        <v-list-item-icon>
          <!-- <svg-icon name="ic_home"></svg-icon> -->
          <v-icon small color="blue-grey darken-2">mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          Dashboard
        </v-list-item-title>
      </v-list-item>

      <!-- ITEM LOOP -->
      <template v-for="(item, key) in items">
        <v-subheader
          v-if="!mini"
          :key="item.title"
          class="font-bold"
          style="background: #F5F5F5">
          {{ item.title }}
        </v-subheader>
        <v-divider :key="key"></v-divider>
        <v-list-item
          v-for="(subItem) in item.items"
          :key="subItem.title"
          :to="subItem.link">
          <v-list-item-icon>
            <v-icon
              small
              color="blue-grey darken-2">
              {{ subItem.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ subItem.title }}
          </v-list-item-title>
        </v-list-item>
      </template>

      <!-- LOGOUT -->
      <v-list-item @click="getLogout">
        <v-list-item-icon>
          <v-icon
            color="blue-grey darken-2">
            $vuetify.icons.icon_logout
          </v-icon>
        </v-list-item-icon>
        <span v-if="!mini">Logout</span>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data () {
    return {
      drawer: true,
      mini: this.$store.getters.sidebarCollapse,
      items: [
        {
          title: 'Zenwel Biz',
          icon: '$vuetify.icons.zenwel_biz_logo',
          active: true,
          items: [
            { title: 'Package', icon: '$vuetify.icons.business_logo', link: '/zenwel-biz/package' },
            { title: 'Business', icon: '$vuetify.icons.business_logo', link: '/zenwel-biz/business' },
            { title: 'Subscription', icon: '$vuetify.icons.subscription_logo', link: '/zenwel-biz/subscription' },
            { title: 'Marketplace', icon: '$vuetify.icons.marketplace_logo', link: '/zenwel-biz/marketplace' },
            { title: 'Biz Deals', icon: '$vuetify.icons.biz_deals', link: '/zenwel-biz/biz-deals' }
          ]
        },
        {
          title: 'Marketplace & Widget',
          icon: '$vuetify.icons.zenwel_biz_logo2',
          active: true,
          items: [
            { title: 'Marketplace User', icon: '$vuetify.icons.marketplace_user', link: '/marketplace-user' },
            { title: 'Deals', icon: '$vuetify.icons.deals_coupon', link: '/deals' }
          ]
        },
        {
          title: 'Admin',
          icon: '$vuetify.icons.admin_logo_sidebar',
          active: true,
          items: [
            { title: 'Admin List', icon: '$vuetify.icons.admin_list', link: '/admin' },
            { title: 'Log', icon: '$vuetify.icons.admin_log', link: '/about' }
          ]
        },
        {
          title: 'Report & Analysist',
          icon: '$vuetify.icons.report_analist_logo',
          active: true,
          items: [
            { title: 'Finance', icon: '$vuetify.icons.admin_log', link: '/report' },
            { title: 'Platform Report Analysis', icon: '$vuetify.icons.platform_report', link: '/about' }
          ]
        }
      ]
    }
  },

  watch: {
    '$store.getters.sidebarCollapse' (payload) {
      this.mini = payload
    }
  },

  methods: {
    getLogout () {
      this.$store.dispatch('LogOut', this.data).then(() => {
        this.$router.push('/login')
      })
    }
  }
}
</script>
